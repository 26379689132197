import React, { useState } from "react";
import { Link } from "gatsby";
import QueryString from "../helper/QueryString";
import CityFinder from "../helper/CityFinder";
import Modal from "react-modal";
import GoogleAnalytics from "../helper/GoogleAnalytics";

let count = 0;
function PromoBar({ context }) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    let special = "";
    let link = "";
    let query = new QueryString();
    let cityFinder = new CityFinder(context.city);
    let city = cityFinder.getCity();
    special = query.getSpecial();
    if (special === "") {
        special = "winter";
        link = `${city.slug ? "/" + city.slug : ""}/preisrechner`;
    } else {
        link = `${special}/preisrechner`;
    }

    function onbeforeunload() {
        this.setState({ modalIsOpen: true });
        return "Changes are not saved."; // return null to avoid pop up
        // };
    }

    const closeModal = () => {
        setModalIsOpen(false);
    };

    // onMouseOut = () => {
    //     if (count === 0) {
    //         this.setState({ modalIsOpen: true });
    //     }
    //     count++;
    // };
    const onMouseOver = () => {
        let onPriceCheckout = false;
        if (typeof window != `undefined`) {
            onPriceCheckout = sessionStorage.getItem("onPriceCheckout");
        }
        if (count === 0 && onPriceCheckout) {
            count++;
            let ga = new GoogleAnalytics();
            ga.sendGTMEvent("PR_Popup_Checkout");

            setModalIsOpen(true);
        }
        // setModalIsOpen(true);
    };

    // 1122px to 935px 60%
    // 935px to 935px 60%
    // 935px to 768px 75%
    let width = 0;
    let widthPer = "600px";
    if (typeof window != `undefined`) {
        width = window.innerWidth;
    }
    if (width > 935 && width < 1122) {
        widthPer = "60%";
    }
    if (width > 768 && width < 935) {
        widthPer = "75%";
    }
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            width: widthPer,
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
        },
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
    };
    return (
        <nav
            className="promobar navbar is-fixed-top is-hidden-mobile"
            onMouseOver={onMouseOver}
        >
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel=""
            >
                <div>
                    <span
                        style={{
                            color: "black",
                            float: "right",
                            cursor: "pointer",
                        }}
                        onClick={closeModal}
                    >
                        {" "}
                        <img
                            src="/img/icons/icons8-close.png"
                            style={{ height: "20px" }}
                        />
                    </span>
                </div>

                <h2
                    style={{
                        color: "#193351",
                        fontSize: "22px",
                        fontWeight: "600",
                    }}
                >
                    Preiskalkulation abbrechen?
                </h2>
                <p style={{ marginTop: "10px" }}>
                    Jetzt Anfrage finalisieren und 5% Extra-Rabatt sichern.
                    Geben Sie dafür im Beratungsgespräch nur den Code
                    "MA24EXTRA5" an.
                </p>
                <p style={{ marginTop: "30px" }}>
                    <button className="btn info-button" onClick={closeModal}>
                        <b>Preiskalkulation abbrechen</b>{" "}
                    </button>

                    <button
                        className="button preisrechner-btn preisrechner-btn-primary newHome3Button"
                        style={{ float: "right" }}
                        onClick={closeModal}
                    >
                        Preiskalkulation fortsetzen
                    </button>
                    {/* <button class="btn default">Default</button> */}
                </p>
            </Modal>

            <Link to={link}>
                {special === "partner" && (
                    <span className="promobar-benefits">
                        <i className="fas fa-piggy-bank left"></i>
                        Partnervorteil: <strong>-10% Winter-Rabatt</strong> auf
                        unsere professionellen Sicherheitskonzepte
                        <i className="fas fa-piggy-bank right"></i>
                    </span>
                )}
                {special === "nebenan" && (
                    <span className="promobar-benefits">
                        <i className="fas fa-piggy-bank left"></i>
                        Nachbarschaftsvorteil:{" "}
                        <strong>-10% Winter-Rabatt</strong> auf unsere
                        professionellen Sicherheitskonzepte
                        <i className="fas fa-piggy-bank right"></i>
                    </span>
                )}
                {special === "winter" && (
                    <span className="promobar-benefits">
                        <i className="fas fa-piggy-bank left"></i>
                        Nur noch für kurze Zeit:{" "}
                        <strong>-10% Winter-Rabatt</strong> auf unsere
                        Alarmanlagen und Videoanlagen
                        <i className="fas fa-piggy-bank right"></i>
                    </span>
                )}
                {special === "spring" && (
                    <span className="promobar-benefits">
                        <i className="fas fa-piggy-bank left"></i>
                        Nur noch für kurze Zeit:{" "}
                        <strong>-10% Winter-Rabatt</strong> auf unsere
                        Alarmanlagen und Videoanlagen
                        <i className="fas fa-piggy-bank right"></i>
                    </span>
                )}
                {special === "Herbst" && (
                    <span className="promobar-benefits">
                        <i className="fas fa-piggy-bank left"></i>
                        Nur noch für kurze Zeit:{" "}
                        <strong>-10% Winter-Rabatt</strong> auf unsere
                        Alarmanlagen und Videoanlagen
                        <i className="fas fa-piggy-bank right"></i>
                    </span>
                )}
                {special === "autumn" && (
                    <span className="promobar-benefits">
                        <i className="fas fa-piggy-bank left"></i>
                        Nur noch für kurze Zeit:{" "}
                        <strong>-10% Winter-Rabatt</strong> auf unsere
                        Alarmanlagen und Videoanlagen
                        <i className="fas fa-piggy-bank right"></i>
                    </span>
                )}
                {special === "fuck-corona" && (
                    <span className="promobar-benefits">
                        <i className="fas fa-piggy-bank left"></i>
                        Gehen Sie in diesen Zeiten auf Nummer sicher:{" "}
                        <strong>-10% "ZU HAUSE BLEIBEN"-Rabatt</strong> auf
                        unsere Sicherheitstechnik
                        <i className="fas fa-piggy-bank right"></i>
                    </span>
                )}
                {special === "end-year" && (
                    <span className="promobar-benefits">
                        <i className="fas fa-piggy-bank left"></i>
                        Nur noch bis zum 31. Dezember:{" "}
                        <strong>
                            -10% JAHRESEND-RABATT & nur 16% Mehrwertsteuer!
                        </strong>
                        <i className="fas fa-piggy-bank right"></i>
                    </span>
                )}
                {special === "new-year" && (
                    <span className="promobar-benefits">
                        <i className="fas fa-piggy-bank left"></i>
                        Auf ein gesundes 2021:{" "}
                        <strong>-10% NEUJAHRS-RABATT</strong> auf unsere
                        Sicherheitstechnik
                        <i className="fas fa-piggy-bank right"></i>
                    </span>
                )}
            </Link>
        </nav>
    );
}

export default PromoBar;
