import React from "react";

const AboutMission = () => {
    return (
        <div className="aboutone">
            <div className="columns is-multiline has-text-centered">
                <h2 className="text-pad ">
                    Als MeinAlarm24 ist es unsere Mission, Menschen ein
                    Sicherheitsgefühl zu geben und sie, ihren Besitz sowie ihre
                    Liebsten durch professionelle und modernste
                    Sicherheitssysteme abzusichern.
                </h2>
                <div className="column is-one-quarter ">
                    <p className="num">
                        <h1>26.000 +</h1>
                        Menschen
                        <br /> abgesichert
                    </p>
                </div>
                <div className="column is-one-quarter">
                    <p className="num">
                        <h1>4.500 +</h1>
                        Anlagen
                        <br /> installiert
                    </p>
                </div>
                <div className="column is-one-quarter">
                    <p className="num">
                        <h1>> 97 %</h1>
                        Zufriedene
                        <br /> Kunden
                    </p>
                </div>
                <div className="column is-one-quarter">
                    <p className="num">
                        <h1>10 +</h1>
                        Standorte in
                        <br /> Deutschland
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AboutMission;
