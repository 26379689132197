import React from "react";
import PropTypes from "prop-types";
import PriceVorteile from "./elements/PriceVorteile";
import GoogleAnalytics from "../../helper/GoogleAnalytics";
import LeadQualificator from "../../helper/LeadQualificator";
import moment from "moment";
import {
    Brand,
    Tuerschloss,
    Alarmanlage,
    Videoueberwachung,
    BeidesInteressant,
    WeissNicht,
} from "./elements/Constants";
import Fade from "react-reveal/Fade";
import { Link } from "gatsby";

let count = 0;
let subtitle;

export class PriceCheckout extends React.Component {
    constructor(props) {
        console.log("price checkout form fired");
        super(props);
        this.updated = this.props.updated;
        this.updated([
            { key: "showBottomButtons", value: false },
            { key: "showSkip", value: false },
        ]);
        this.sending = false;
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0);
        }
        if (this.props.prices.securitySystem === Brand) {
            this.message =
                "Gute Nachricht! Eine erste Preisindikation wird für Sie ermittelt.";
        } else {
            this.message =
                "Gute Nachricht! Der Preis für Ihre Anlage wird ermittelt.";
        }
        let leadQualificator = new LeadQualificator();
        if (this.props.prices.postalCode && this.props.prices.budget) {
            leadQualificator.setLeadPotentiality(
                this.props.prices.postalCode,
                this.props.prices.budget,
                this.updated
            );
        }
        this.state = { modalIsOpen: false };

        // if (typeof window != `undefined`) {
    }

    componentDidMount() {
        let ga = new GoogleAnalytics();
        ga.sendEvent("preisrechner", "reached", "checkout");
        console.log(
            "sendGTMEvent with prefix preisrechner",
            "reached",
            "checkout"
        );
        this.sendEvent();
        if (typeof window != `undefined`) {
            sessionStorage.setItem("onPriceCheckout", true);
        }
        if (typeof window != `undefined`) {
            console.log("componentDidMount fires in checkout");
            let only_mobile_500px = document.getElementById(
                "only-mobile-500px-Max"
            );
            let box = document.getElementById("boxCalc");
            let pfooter = document.getElementById("price-footer2");
            // console.log("box is :", box);
            setTimeout(() => {
                only_mobile_500px.style.display = "none";
                if (pfooter) {
                    pfooter.style.display = "none";
                }
            }, 100);

            let path = window.location.pathname;
            path = path.replace(/\//g, "");
            const removeArray = [
                "schliessanlagenpreisrechner",
                "videoueberwachungpreisrechner",
                "alarmanlagenpreisrechner",
                "brandmeldeanlagenpreisrechner",
                "preisrechner",
            ];
            box.style.paddingTop = "0%";
            // if (removeArray.includes(path) && window.innerWidth > 768) {
            // }
            // if (removeArray.includes(path) && window.innerWidth <= 500) {
            //     box.style.paddingTop = "9.6%";
            // }
            // if (
            //     removeArray.includes(path) &&
            //     window.innerWidth <= 768 &&
            //     window.innerWidth >= 500
            // ) {
            //     box.style.paddingTop = "3.6%";
            // }
        }
    }
    componentWillUnmount() {
        console.log("componentWillUnmount fired");
        if (typeof window != `undefined`) {
            sessionStorage.removeItem("onPriceCheckout");
            console.log("componentDidUnMount fires in checkout");
            let only_mobile_500px = document.getElementById(
                "only-mobile-500px-Max"
            );
            only_mobile_500px.style.display = "block";
            let box = document.getElementById("boxCalc");
            box.style.paddingTop = "1.25rem";
        }
    }

    sendEvent() {
        let ga = new GoogleAnalytics();
        var prefix = "unknown";
        if (this.props.prices.securitySystem === Brand) {
            prefix = "f";
        } else if (this.props.prices.securitySystem === Tuerschloss) {
            prefix = "d";
        } else if (this.props.prices.securitySystem === Alarmanlage) {
            prefix = "a";
        } else if (this.props.prices.securitySystem === Videoueberwachung) {
            prefix = "v";
        } else if (
            this.props.prices.securitySystem === BeidesInteressant ||
            this.props.prices.securitySystem === WeissNicht
        ) {
            prefix = "av";
        }
        console.log("sendGTMEvent with prefix", prefix);
        ga.sendGTMEvent("calculator/" + prefix + "/Checkout");
    }

    vorteile(name, mainStyle, subStyle) {
        return (
            <div className={"columns " + name + " " + mainStyle}>
                <div
                    className={
                        "column is-vertical-center " +
                        name +
                        "-left " +
                        subStyle
                    }
                >
                    <i className="fas fa-check"></i> Kostenlose
                    Sicherheitsberatung
                </div>
                <div
                    className={
                        "column is-vertical-center " +
                        name +
                        "-middle " +
                        subStyle
                    }
                >
                    <i className="fas fa-check"></i> Alles aus einer Hand
                </div>
                <div
                    className={
                        "column is-vertical-center " +
                        name +
                        "-right " +
                        subStyle
                    }
                >
                    <i className="fas fa-check"></i> Systeme vom Testsieger
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="content checkout checkoutMT" key="checkout">
                <div className="margin-top-540px">
                    <Fade right>
                        <div className="columns checkout-top is-mobile">
                            <div className="column is-narrow checkout-top-left has-text-centered">
                                <i className="fas fa-check"></i>
                            </div>
                            <div className="column is-vertical-center checkout-top-middle">
                                <span className="is-size-4-tablet">
                                    {this.message}
                                </span>
                                <div className="arrow-down"></div>
                            </div>
                        </div>
                        <div className="columns checkout-middle">
                            <div className="column">
                                <div className="columns">
                                    <div className="column has-text-centered checkout-middle-top">
                                        Wer soll die <strong>kostenlose</strong>{" "}
                                        Preiskalkulation erhalten?
                                    </div>
                                </div>
                                <form
                                    id="checkoutForm"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        if (e.target.checkValidity()) {
                                            this.sending = true;
                                            this.updated([
                                                {
                                                    key: "generatedTime",
                                                    value: moment()
                                                        .utc()
                                                        .format(
                                                            "YYYY-MM-DDTHH:mm:ss"
                                                        ),
                                                },
                                                {
                                                    key: "sendEmail",
                                                    value: true,
                                                },
                                            ]);
                                        }
                                    }}
                                >
                                    <div className="columns">
                                        <div className="column checkout-middle-left">
                                            <div className="columns">
                                                <div className="column">
                                                    <label className="label-container">
                                                        Herr
                                                        <input
                                                            type="radio"
                                                            required
                                                            name="salutation"
                                                            value="Herr"
                                                            onChange={this.update.bind(
                                                                this
                                                            )}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                    <label className="label-container">
                                                        Frau
                                                        <input
                                                            type="radio"
                                                            required
                                                            name="salutation"
                                                            value="Frau"
                                                            onChange={this.update.bind(
                                                                this
                                                            )}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="columns">
                                                <div className="column vorname">
                                                    <input
                                                        className="input"
                                                        name="firstName"
                                                        required
                                                        placeholder="Vorname"
                                                        onChange={this.update.bind(
                                                            this
                                                        )}
                                                    />
                                                </div>
                                                <div className="column nachname">
                                                    <input
                                                        className="input"
                                                        name="lastName"
                                                        required
                                                        placeholder="Nachname"
                                                        onChange={this.update.bind(
                                                            this
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="columns">
                                                <div className="column">
                                                    <input
                                                        className="input"
                                                        type="email"
                                                        required
                                                        name="email"
                                                        placeholder="E-Mail"
                                                        onChange={this.update.bind(
                                                            this
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="columns">
                                                <div className="column">
                                                    <input
                                                        className="input"
                                                        type="tel"
                                                        required
                                                        name="phone"
                                                        placeholder="Telefon"
                                                        onChange={this.update.bind(
                                                            this
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="columns">
                                                <div className="column datenschutz">
                                                    <i className="fas fa-lock"></i>
                                                    Die Sicherheit Ihrer Daten
                                                    ist uns wichtig! Ihre Daten
                                                    werden streng vertraulich
                                                    behandelt und nicht an
                                                    Dritte weitergegeben.
                                                </div>
                                            </div>
                                            <div className="columns">
                                                <div className="column submit">
                                                    <button
                                                        id="checkoutSubmit"
                                                        className={
                                                            "button preisrechner-btn preisrechner-btn-primary checkout-button" +
                                                            (this.sending
                                                                ? " is-loading"
                                                                : "")
                                                        }
                                                    >
                                                        Preis berechnen
                                                    </button>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: "20px",
                                                    textAlign: "center",
                                                }}
                                                className="only-mobile"
                                            >
                                                <PriceVorteile
                                                    name="checkout-bottom vorteil"
                                                    mainStyle="only-desktop-flex"
                                                    subStyle="has-text-centered"
                                                />
                                            </div>
                                            <div
                                                className="columns is-mobile is-hidden-tablet is-vcentered"
                                                style={{
                                                    backgroundColor: "#f2f2f2",
                                                    margin: "0",
                                                    marginTop: "12px",
                                                    marginBottom: "12px",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <div
                                                    className="column is-narrow"
                                                    style={{
                                                        paddingTop: "0px",
                                                        paddingBottom: "0px",
                                                        paddingRight: "0px",
                                                        paddingLeft: "10px",
                                                    }}
                                                >
                                                    <i
                                                        className="fas fa-piggy-bank left is-size-3"
                                                        style={{
                                                            color: "#1895ff",
                                                            verticalAlign:
                                                                "middle",
                                                        }}
                                                    ></i>
                                                </div>
                                                <div
                                                    className="column is-size-6 has-text-centered"
                                                    style={{
                                                        paddingLeft: "0px",
                                                    }}
                                                >
                                                    Jetzt noch für kurze Zeit:{" "}
                                                    <strong>
                                                        -10% Winter-Rabatt
                                                    </strong>{" "}
                                                    auf unsere
                                                    Sicherheitstechnik
                                                </div>
                                            </div>

                                            <div
                                                style={{ marginTop: "10px" }}
                                                className="columns is-mobile is-hidden-tablet is-vcentered"
                                            >
                                                <div className="column">
                                                    <img
                                                        src="/img/210527 Screenshot Sicherheitskonzept.png"
                                                        onClick={() =>
                                                            document
                                                                .getElementById(
                                                                    "checkoutSubmit"
                                                                )
                                                                .click()
                                                        }
                                                        alt="persönliche Preiskalkulation"
                                                    />
                                                </div>
                                                <div className="column">
                                                    <img
                                                        src="/img/220322_DIN_Logo.png"
                                                        onClick={() =>
                                                            document
                                                                .getElementById(
                                                                    "checkoutSubmit"
                                                                )
                                                                .click()
                                                        }
                                                        alt="persönliche Preiskalkulation"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column checkout-middle-right is-hidden-mobile">
                                            <div
                                                style={{ marginTop: "0px" }}
                                                className="is-hidden-mobile columns"
                                            >
                                                <div className="column ">
                                                    <div>
                                                        <p
                                                            style={{
                                                                marginBottom:
                                                                    "2px",
                                                            }}
                                                        >
                                                            <b className="is-size-7">
                                                                Preiskalkulation
                                                                (Beispiel):
                                                            </b>
                                                        </p>
                                                        <img
                                                            src="/img/210527 Screenshot Sicherheitskonzept.png"
                                                            onClick={() =>
                                                                document
                                                                    .getElementById(
                                                                        "checkoutSubmit"
                                                                    )
                                                                    .click()
                                                            }
                                                            alt="persönliche Preiskalkulation"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="column">
                                                    <div>
                                                        <p
                                                            style={{
                                                                marginBottom:
                                                                    "0px",
                                                            }}
                                                        >
                                                            <b className="is-size-7">
                                                                Gefördert durch:
                                                            </b>
                                                        </p>
                                                        <img
                                                            style={{
                                                                padding: "2px",
                                                                height: "100px",
                                                            }}
                                                            src="/img/220322_DIN_Logo.png"
                                                            onClick={() =>
                                                                document
                                                                    .getElementById(
                                                                        "checkoutSubmit"
                                                                    )
                                                                    .click()
                                                            }
                                                            alt="persönliche Preiskalkulation"
                                                        />
                                                    </div>
                                                    <div
                                                        className="columns is-vcentered"
                                                        style={{
                                                            backgroundColor:
                                                                "#f2f2f2",
                                                            margin: "0",
                                                            marginTop: "8px",
                                                            marginBottom: "0px",
                                                            borderRadius: "5px",
                                                        }}
                                                    >
                                                        <div className="column">
                                                            <div
                                                                className="has-text-centered"
                                                                style={{
                                                                    paddingTop:
                                                                        "0px",
                                                                    paddingBottom:
                                                                        "5px",
                                                                    paddingRight:
                                                                        "0px",
                                                                    paddingLeft:
                                                                        "0px",
                                                                }}
                                                            >
                                                                <i
                                                                    className="fas fa-piggy-bank left is-size-3"
                                                                    style={{
                                                                        color: "#1895ff",
                                                                        verticalAlign:
                                                                            "middle",
                                                                    }}
                                                                ></i>
                                                            </div>
                                                            <div
                                                                className="is-size-7 has-text-centered"
                                                                style={{
                                                                    paddingLeft:
                                                                        "0px",
                                                                }}
                                                            >
                                                                Jetzt noch für
                                                                kurze Zeit:{" "}
                                                                <strong>
                                                                    -10%
                                                                    Winter-Rabatt
                                                                </strong>{" "}
                                                                auf unsere
                                                                Sicherheitstechnik
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Fade>
                </div>
                <div
                    style={{
                        marginTop: "20px",
                        textAlign: "center",
                    }}
                    className="only-desktop"
                >
                    <PriceVorteile
                        name="checkout-bottom vorteil"
                        mainStyle="only-desktop-flex"
                        subStyle="has-text-centered"
                    />
                </div>
            </div>
        );
    }

    update(event) {
        this.updated([{ key: event.target.name, value: event.target.value }]);
    }
}

PriceCheckout.propTypes = {
    updated: PropTypes.func.isRequired,
};

export default PriceCheckout;
